import { companies } from '@shared/serializers/companies.serializer';
import { locations } from '@shared/serializers/locations.serializer';
import { getSerializer } from '@shared/serializers/serializer';
import { assetsCategory } from './assets-category.serializer';
import { users } from './users.serializer';

export const contacts = {
  type: 'contact',
  attributes: [
    'company',
    'locations',
    'subLocation',
    'userType',
    'createLogin',
    'role',
    'companyName',
    'firstName',
    'lastName',
    'email',
    'phone',
    'countryCode',
    'createdAt',
    'updatedAt',
    'files',
    'notes',
    'categories',
    'user'
  ],
  company: {
    ref: 'id',
    attributes: companies.attributes,
    included: false
  },
  locations: {
    ref: 'id',
    attributes: locations.attributes,
    included: false
  },
  subLocation: {
    ref: 'id',
    attributes: locations.attributes,
    included: false
  },
  categories: {
    ref: 'id',
    attributes: assetsCategory.attributes,
    included: false
  },
  user: {
    ref: 'id',
    attributes: users.attributes,
    included: false
  }
};

export const ContactsSerializer = getSerializer(contacts);
